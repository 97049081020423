<template>
  <div>
    <el-form ref="form" :model="form" label-width="80px" style="display: flex">
      <div class="el-lt" style="width: 88%;">
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item label="承运商" :class="$i18n.locale">
              <el-select v-model="form.carrierCode" value-key="id" clearable filterable style="width:100%;">
                <el-option
                  v-for="item in newCarrierList"
                  :key="item.id"
                  :label="item.vendorName"
                  :value="item.vendorCode"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="费用片段" :class="$i18n.locale">
              <el-select v-model="form.feeSnippetCode" value-key="id" clearable filterable style="width:100%;">
                <el-option
                  v-for="item in _getAllCommodityDict('FIN_COST_FRAGMENT')"
                  :key="item.val"
                  :label="item.label"
                  :value="item.val"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="启停状态" :class="$i18n.locale">
              <el-select v-model="form.delFlag" value-key="id" clearable filterable style="width:100%;">
                <el-option label="启用" :value="0" />
                <el-option label="禁用" :value="1" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="创建日期">
              <el-date-picker
                v-model="form.value1"
                type="daterange"
                range-separator="~"
                value-format="yyyy-MM-dd"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </el-form-item>
          </el-col>

        </el-row>
        <el-row :span="24" class="mb-3">
          <el-col :span="24">
            <el-button type="primary" @click="showAddform('add')">添加</el-button>
          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width:12%">
        <el-col class="row-center">
          <el-button type="primary" :loading="queryLoading" @click="queryClick()">{{
            $t("page.search")
          }}</el-button>
          <el-button @click="resetClick()">{{ $t("page.reset") }}</el-button>
        </el-col>
      </el-row>
    </el-form>
    <Table
      ref="tableRef"
      :columns="projectManageTableColumns"
      :table-data="tableData"
      :loading="queryLoading"
      :page-obj="pager"
    >
      <el-table-column slot="operate" label="操作" fixed="right">
        <template slot-scope="scope">
          <el-button v-if="scope.row.delFlag==0" type="text" @click="showAddform('edit',scope.row)">编辑</el-button>
          <el-button
            v-if="scope.row.delFlag"
            type="text"
            :loading="scope.row.delFlagLoading"
            @click="delFlagHandle(scope.row)"
          >启用</el-button>
          <el-button v-else type="text" :loading="scope.row.delFlagLoading" @click="delFlagHandle(scope.row)">禁用
          </el-button>
        </template>
      </el-table-column>
    </Table>
    <Paging :pager="pager" end :page-obj="pager" :page-sizes="[10,20,50,100]" @update="updateForm" />
    <el-dialog title="费用项目" :visible.sync="centerDialogVisible" width="30%" center :close-on-click-modal="false">
      <el-form ref="addForm" :model="addForm" :rules="rules" label-width="100px" class="demo-ruleForm">
        <el-form-item label="承运商" prop="carrierCode">
          <el-select v-model="addForm.carrierCode" value-key="id" clearable filterable style="width:100%;">
            <el-option
              v-for="item in newCarrierList"
              :key="item.id"
              :label="item.vendorName"
              :value="item.vendorCode"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="费用片段" prop="feeSnippetCode">
          <el-select v-model="addForm.feeSnippetCode" value-key="id" clearable filterable style="width:100%;">
            <el-option v-for="item in feeSnippetList" :key="item.val" :label="item.label" :value="item.val" />
          </el-select>
        </el-form-item>
        <el-form-item label="费用类别" prop="feeTypeCode">
          <el-select v-model="addForm.feeTypeCode" value-key="id" clearable filterable style="width:100%;">
            <el-option
              v-for="item in _getAllCommodityDict('FIN_COST_CLASS')"
              :key="item.val"
              :label="item.label"
              :value="item.val"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="费用项目" prop="feeItem">
          <el-input v-model.trim="addForm.feeItem" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="saveLoading" @click="save">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import Paging from '@/components/Paging'
import Table from '@/components/Table'
import { projectManageTableColumns } from '@/utils/table-columns.js'
import { listFeeItemByPage, update, add, enableOrDisable } from '@/api/carriers'
import store from '@/store'
import { getVendorListByVendorType } from '@/api/select.js'
import { deepClone } from '@/utils'
export default {
  components: { Paging, Table },
  mixins: [commodityInfoDict],

  data() {
    return {
      newCarrierList: [],
      feeSnippetList: [],
      feeTypeList: [],
      saveLoading: false,
      rules: {
        carrierCode: [{ required: true, message: '必填', trigger: 'change' }],
        feeSnippetCode: [{ required: true, message: '必填', trigger: 'change' }],
        feeTypeCode: [{ required: true, message: '必填', trigger: 'change' }],
        feeItem: [{ required: true, message: '必填', trigger: 'input' }]
      },
      addForm: {
        carrierCode: '',
        feeSnippetCode: '',
        feeTypeCode: '',
        feeItem: '',
        feeType: '', carrier: '', feeItemCode: '', feeSnippet: ''
      },
      centerDialogVisible: false,
      title: '',
      form: {
        value1: [],
        descs: 'create_time',
        delFlag: ''
      },
      queryLoading: false,
      projectManageTableColumns,
      pager: {
        current: 1,
        size: 10,
        total: 0
      },
      tableData: []
    }
  },
  computed: {
    queryParams() {
      const { value1, feeSnippetCode, carrierCode, descs, delFlag } = this.form
      const [createDateStart, createDateEnd] = value1 || []
      return Object.assign({}, this.pager, { createDateStart, createDateEnd, carrierCode, feeSnippetCode, descs, delFlag })
    },
    addParams() {
      const { carrierCode, feeSnippetCode, feeTypeCode, feeItem } = this.addForm
      const nameObj = Object.assign({}, { feeSnippet: this.findName(feeSnippetCode, 'FIN_COST_FRAGMENT') },
        { feeType: this.findName(feeTypeCode, 'FIN_COST_CLASS') }
        , { carrier: this.newCarrierList.find(item => String(item.vendorCode) === String(carrierCode)).vendorName }, { feeItemCode: feeItem },
      )
      return Object.assign({}, {
        createById: store.getters.userId,
        createByName: store.getters.name
      }, {
        modifyById: store.getters.userId,
        modifyByName: store.getters.name
      }, this.addForm, nameObj)
    },
    editParams() {
      const { carrierCode, feeSnippetCode, feeTypeCode, feeItem } = this.addForm
      return Object.assign({}, this.addForm, {
        modifyById: store.getters.userId,
        modifyByName: store.getters.name
      }, { feeSnippet: this.findName(feeSnippetCode, 'FIN_COST_FRAGMENT') },
      { feeType: this.findName(feeTypeCode, 'FIN_COST_CLASS') }
      , { carrier: this.newCarrierList.find(item => String(item.vendorCode) === String(carrierCode)).vendorName }, { feeItemCode: feeItem })
    }
  },
  watch: {
    'centerDialogVisible'(val) {
      if (!val) {
        this.addForm = this.$options.data.call(this).addForm
        this.$refs.addForm.clearValidate()
        this.$refs.addForm.resetFields()
      }
    }
  },
  created() {

  },
  mounted() {
    this._getVendorListByVendorType()
    this.queryClick()
    this.feeSnippetList = this._getAllCommodityDict('FIN_COST_FRAGMENT').filter(item => item.val !== 'FYPD009')
  },
  methods: {
    async _getVendorListByVendorType() {
      const { datas } = await getVendorListByVendorType({ vendorType: '6' })
      this.newCarrierList = datas
    },
    async delFlagHandle(row) {
      const { delFlag } = row
      try {
        this.$set(row, 'delFlagLoading', true)
        const params = Object.assign({}, row, { delFlag: delFlag + '' === '0' ? 1 : 0, modifyById: store.getters.userId, modifyByName: store.getters.name })
        const { code, msg } = await enableOrDisable(params)
        if (code === 0) {
          this.$notify({
            message: msg,
            type: 'success'
          })
          this.queryClick()
        }
      } finally {
        this.$set(row, 'delFlagLoading', false)
      }
    },
    findName(code, key) {
      const { label } = this._getAllCommodityDict(key).find(item => item.val === code)
      return label
    },
    handleFormChange(form, clearList) {
      clearList.forEach(key => { this[form][key] = Array.isArray(this[form][key]) ? [] : '' })
    },

    save() {
      this.$refs.addForm.validate(async valid => {
        if (valid) {
          try {
            this.saveLoading = true
            if (this.addForm.id) {
              // console.log(this.editParams)
              const { code, msg } = await update(this.editParams)
              if (code === 0) {
                this.$notify({
                  message: msg,
                  type: 'success'
                })
                this.centerDialogVisible = false
                this.queryClick()
              }
            } else {
              const { code, msg } = await add(this.addParams)
              if (code === 0) {
                this.$notify({
                  message: msg,
                  type: 'success'
                })
                this.centerDialogVisible = false
                this.queryClick()
              }
            }
          } finally {
            this.saveLoading = false
          }
        }
      })
    },
    resetClick() {
      this.form = this.$options.data.call(this).form
      this.queryClick()
    },
    queryClick() {
      this.pager.current = 1
      this.handleQuery()
    },
    async handleQuery() {
      try {
        this.queryLoading = true
        const { datas: { pager, records }} = await listFeeItemByPage(this.queryParams)
        this.pager = pager
        this.tableData = records.map(item => { return { ...item, delFlagLoading: false } })
      } finally {
        this.queryLoading = false
      }
    },
    updateForm(val) {
      this.pager = val
      this.handleQuery()
    },
    showAddform(flag, row) {
      if (flag === 'add') {
        this.title = '费用项目'
      } else {
        const obj = deepClone(row)
        this.title = row.feeItem
        this.addForm = obj
      }
      this.centerDialogVisible = true
    }

  }
}
</script>

<style scoped lang="scss">
/deep/ .el-date-editor .el-range-input {
  width: 30% !important;
}
</style>

